.admin-dashboard {
    width: 100%;
    height: 100vh;
    position: relative;
    // overflow: hidden;

    .admin-inner {
        max-width: 100%;
        max-height: 100%;
        min-width: 100%;
        // background: red;
        min-height: 100%;

        .admin-card-box {}

        .admin-card {
            width: 100%;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 10px;
            overflow: hidden;

            .admin-icon {
                font-size: 40px !important;
                color: #fff;
                background: #345d3b;
                font-weight: bold;
                width: 30%;
            }

            .admin-icon+div {
                width: 70%;

                h3 {
                    color: #345d3b;
                }
            }
        }
    }

    .admin-nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }

    .admin-container {
        width: 100%;
        height: 100%;
        padding-top: 85px;
        position: relative;
        padding-left: 100px;
        background-color: #f7fcf7;

        .aside {
            background: #345d3b;
            position: absolute;
            top: 0;
            left: 0;
            width: 100px;
            height: 100%;

            .aside-child {
                padding-top: 65px;
                height: 100%;
                width: 100%;
                background: #345d3b;
                position: absolute;
                top: 0%;
                left: 0;
                // margin-top: 10px;

                .aside-inner {
                    overflow-y: auto;
                    max-height: 85%;
                    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

                    // &::-webkit-scrollbar {
                    //     width: 0px;
                    // }
                }

                .aside-icon {
                    font-size: 30px;
                    color: white;
                }
            }


        }
    }
}

.create-user {
    width: 100%;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    form {
        background: #f7fcf7;
        padding: 30px 40px;
        min-width: 500px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }

}

.table-container {
    margin-top: 10px;
    // height: 60vh;
    overflow: hidden;
}


#multiselectContainerReact {
    max-width: 450px;
    min-width: 400px;
}

.table-sortlabel {
    color: #ffffff;

    &:hover {
        color: #c7e5cd !important;

        svg {
            color: #c7e5cd !important;
        }

        span {
            color: #c7e5cd !important;
        }
    }
}

.table-sortlabel.active {
    color: #c7e5cd !important;

    svg {
        color: #c7e5cd !important;
    }

    span {
        color: #c7e5cd !important;
    }
}