body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.social-login-buttons div{
  width: 42px;
  height: 42px;
}

.social-login-buttons div img{
  width: 100%;
  height: 100%;
}
.css-b62m3t-container{
  width: 100% !important
}
@import './style/main';
@import './style/admin';
table th {
  border: none !important;
}


.filer_boxs{
  width: 100%;
  margin-top: 20px;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.filer_box{
  background-color: white;
  width: 100%;
  height: 300px;
  border-radius: 20px;
 
}
.filer_box textarea{
  width: 100%;
  padding: 10px 20px;
  resize: none;
  outline: none;
  border-radius: 15px;
}
.filter_send{
  float:right
}
.filter_send button{
  padding: 5px 50px;
  background-color: #345d3b;
  color:white;
  border-radius: 30px;

}
.filter_send button:hover{
  background-color: rgba(137, 43, 226, 0.674);

}
.filer_box p{
  font-weight: 600;
  font-size: larger;
}
.boradcast {
  margin: 20px 0;
font-weight: 700;

width: 100%;
}
.filter_table{
  width: 100%;
}
.Admission_date{
  border: 1px solid rgb(223, 205, 205);
  width: 100%;
  padding: 10px 10px;
  border-radius: 5px;
}